import React, { useEffect, useRef, useState } from "react";
import { Button, DataTableAddButton } from "../../../components";
import { GridLayout } from "../../../layouts";
import { registerAllModules } from "handsontable/registry";
import { HotTableClass as HotTable } from "@handsontable/react";

import { adjustColumnHeaderColour } from "../../../utils/handsontable-configuration/workshifts/render-configuration";
import range from "lodash/range";
import ShiftService from "../../../services/shiftService";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import NotificationFloat from "../../../components/NotificationFloat/NotificationFloat";
import TabbarTeritary from "../../../components/tab-bar/tabbar-teritary/TabbarTeritary";
import ResultService from "../../../services/resultService";
import { useTranslation } from "react-i18next";
import { AppInsightService } from "../../../services";
import {
  BudgetDataErrorResponse,
  ShiftsBudgetDataResponse,
  ShiftsDataResponse,
  TotalBudgetDataResponse,
} from "../../../interfaces/responses";
import { AxiosError, AxiosResponse } from "axios";
import { WorkShiftsTableData } from "../../../interfaces/work-shifts";
import useGetFormalCompetencesByCareUnit from "../../../common/hooks/data/useGetFormalCompetencesByCareUnit";
import useGetShiftWorkCompetenceAllocations from "./hooks/useGetShiftWorkCompetenceAllocations";
import { HandsOnTableColumn } from "../../../interfaces/handsOnTable/handsOnTableColumn";
import useShiftsTableColumns from "./hooks/useShiftsTableColumns";
import useWorkShiftsUtilities from "./hooks/useWorkShiftsUtilities";
import { getWorkShiftDefaultDataSchema } from "../../../utils/handsontable-configuration/workshifts/constants";
import { HandsontableDataSchema } from "../../../interfaces/handsOnTable/handsontableDataSchema";
import { useWorkShiftValidators } from "./hooks/useWorkShiftValidators";
import { productionShareColumns } from "../../../utils/constants/productionShares";
import { FormalCompetenceColumnSpan } from "../../../interfaces/handsOnTable/formalCompetenceColumnSpan";
import { HandsontableNestedHeaders } from "../../../interfaces/handsOnTable/handsontableNestedHeaders";
import { useBlocker } from "react-router-dom";
import SaveChangesConfirmation from "../../../components/SaveChangesConfirmation/SaveChangesConfirmation";
import { DynamicType } from "../../../types/components";

const ShiftsHome = () => {
  useEffect(() => {
    AppInsightService.trackPageView({ name: "ShitsHome" });
  }, []);
  registerAllModules();
  const dataTable = useRef<HotTable>(null);

  const [tableData, setTableData] = useState<DynamicType[]>([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(true);
  const [isPercentageMarkAtTheEnd, setIsPercentageMarkAtTheEnd] = useState(false);
  const [shiftFailNotification, setShiftFailNotification] = useState(false);
  const selectedOrgCareUnitTypeId = useSelector((state: RootState) => state.globalFilter.filterCareUnitType);
  const [isValidProductionAndCompetenceShares, setIsValidProductionAndCompetenceShares] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [algoExeSuccessNotification, setAlgoExeSuccessNotification] = useState(false);
  const [algoExeFailNotification, setAlgoExeFailNotification] = useState("");

  const [numberOfCompetenceColumns, setNumberOfCompetenceColumns] = useState<number>(0);

  const [lastColumnIndex, setLastColumnIndex] = useState<number>(0);

  const [nestedHeaders, setNestedHeaders] = useState<HandsontableNestedHeaders[][]>([[]]);

  const initialHiddenColumns = range(13, lastColumnIndex);
  const [hiddenColumns, setHiddenColumns] = useState(initialHiddenColumns);

  const shiftDetailsHiddenColumns = [...range(2, 13), ...range(19, lastColumnIndex)];
  const shiftDetailsShowColumns = range(13, 19);

  const competencyMixHiddenColumns = [...range(2, 19), ...range(19 + numberOfCompetenceColumns, lastColumnIndex)];
  const competencyMixShowColumns = range(19, numberOfCompetenceColumns);

  const noOfProductionColumns = 8;

  const typesOfProductionHiddenColumns = [
    ...range(2, 19 + numberOfCompetenceColumns),
    ...range(19 + numberOfCompetenceColumns + noOfProductionColumns, lastColumnIndex),
  ];

  const typesOfProductionShowColumns = range(
    19 + numberOfCompetenceColumns,
    19 + numberOfCompetenceColumns + noOfProductionColumns
  );

  const weeklyPhasingHiddenColumns = [...range(2, 19 + numberOfCompetenceColumns + noOfProductionColumns)];
  const weeklyPhasingShowColumns = range(19 + numberOfCompetenceColumns + noOfProductionColumns, lastColumnIndex);

  const [workShiftTableData, setWorkShiftsTableData] = useState<WorkShiftsTableData[]>([]);

  const { t: translate } = useTranslation();

  const tabs = [
    translate("Shift"),
    translate("ShiftDetails"),
    translate("CompetenceMix"),
    translate("ProductionMix"),
    translate("Availability"),
  ];

  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);

  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);

  const formalCompetenceDataHandler = useGetFormalCompetencesByCareUnit(careUnitId);

  const allocationsHandler = useGetShiftWorkCompetenceAllocations(careUnitId, scenario);

  const colsConfigurator = useShiftsTableColumns();

  const utitlies = useWorkShiftsUtilities();

  const validators = useWorkShiftValidators();

  const dynamicCompetencesNamesRef = useRef<string[]>([]);

  const convertToTableData = (shifts: WorkShiftsTableData[]): DynamicType[] => {
    const rows: DynamicType[] = [];

    shifts.forEach((item) => {
      const propertyNames: string[] = [];

      formalCompetenceDataHandler.data.forEach((formalCompetence) => {
        formalCompetence.workCompetences.forEach((workCompetence) => {
          propertyNames.push(workCompetence.id?.toString() as string);
        });
      });

      const dynamicObject = utitlies.createDynamicType(propertyNames, item, allocationsHandler.data);
      rows.push(dynamicObject);
    });

    return rows;
  };

  useEffect(() => {
    if (workShiftTableData && !allocationsHandler.isLoading) {
      const rows = convertToTableData(workShiftTableData);
      setTableData(rows);
    }
  }, [workShiftTableData, allocationsHandler.data]);

  const [colConfigs, setColConfigs] = useState<HandsOnTableColumn[]>([]);

  const [workshiftDataSchema, setWorkShiftDataSchema] = useState<HandsontableDataSchema>({});

  useEffect(() => {
    const cols = colsConfigurator.getCols(
      localStorage.getItem("i18nextLng") === "en" || localStorage.getItem("i18nextLng") === "en-US" ? "en-US" : "sv-SE",
      formalCompetenceDataHandler.data
    );

    const competenceColumnIds = colsConfigurator.getCompetenceColumnIds(formalCompetenceDataHandler.data);

    dynamicCompetencesNamesRef.current = competenceColumnIds;
    setNumberOfCompetenceColumns(competenceColumnIds.length);

    if (selectedOrgCareUnitTypeId) {
      const dataSchema = getWorkShiftDefaultDataSchema(
        dynamicCompetencesNamesRef.current,
        formalCompetenceDataHandler.data,
        selectedOrgCareUnitTypeId
      );

      setWorkShiftDataSchema(dataSchema);
    }

    setLastColumnIndex(cols.length - 4);
    setColConfigs(cols as HandsOnTableColumn[]);
  }, [formalCompetenceDataHandler.data, selectedOrgCareUnitTypeId]);

  const isCareUnitReadOnly = useSelector((state: RootState) => state.permission.careUnitReadOnly);

  const [isTableFiltered, setIsTableFiltered] = useState(false);

  const [shiftsTotals, setShiftsTotals] = useState<{
    cost: number;
    fte: number;
    weekend_shifts: number;
    fte_weekend_shifts: number;
  }>({
    cost: 0,
    fte: 0,
    weekend_shifts: 0,
    fte_weekend_shifts: 0,
  });

  const [isTableDataEdited, setIsTableDataEdited] = useState(false);

  const [isSaveChangesNotificationOpen, setIsSaveChangesNotificationOpen] = useState(false);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) => isTableDataEdited && currentLocation.pathname !== nextLocation.pathname
  );

  useEffect(() => {
    if (blocker.state === "blocked") {
      setIsSaveChangesNotificationOpen(true);
    }
  }, [blocker.state]);

  useEffect(() => {
    if (algoExeSuccessNotification) {
      const timer = setTimeout(() => {
        setAlgoExeSuccessNotification(false);
      }, 4000);

      return () => clearTimeout(timer);
    } else if (algoExeFailNotification !== "") {
      const timer = setTimeout(() => {
        setAlgoExeFailNotification("");
      }, 4000);

      return () => clearTimeout(timer);
    }
  }, [algoExeSuccessNotification, algoExeFailNotification]);

  useEffect(() => {
    if (isCareUnitReadOnly !== null) {
      dataTable.current?.__hotInstance?.updateSettings({
        readOnly: isCareUnitReadOnly,
      });
    }
  }, [isCareUnitReadOnly]);

  useEffect(() => {
    if (selectedTabIndex === 0) {
      setHiddenColumns(initialHiddenColumns);
      hiddenColumnsPlugin?.hideColumns(initialHiddenColumns);
      dataTable.current?.__hotInstance?.render();
    } else if (selectedTabIndex === 1) {
      hiddenColumnsPlugin?.showColumns(shiftDetailsShowColumns);
      hiddenColumnsPlugin?.hideColumns(shiftDetailsHiddenColumns);
      setHiddenColumns(shiftDetailsHiddenColumns);
      dataTable.current?.__hotInstance?.render();
    } else if (selectedTabIndex === 2) {
      hiddenColumnsPlugin?.showColumns(competencyMixShowColumns);
      hiddenColumnsPlugin?.hideColumns(competencyMixHiddenColumns);
      setHiddenColumns(competencyMixHiddenColumns);
      dataTable.current?.__hotInstance?.render();
    } else if (selectedTabIndex === 3) {
      hiddenColumnsPlugin?.showColumns(typesOfProductionShowColumns);
      hiddenColumnsPlugin?.hideColumns(typesOfProductionHiddenColumns);
      setHiddenColumns(typesOfProductionHiddenColumns);
      dataTable.current?.__hotInstance?.render();
    } else if (selectedTabIndex === 4) {
      hiddenColumnsPlugin?.showColumns(weeklyPhasingShowColumns);
      hiddenColumnsPlugin?.hideColumns(weeklyPhasingHiddenColumns);
      setHiddenColumns(weeklyPhasingHiddenColumns);
      dataTable.current?.__hotInstance?.render();
    }
  }, [selectedTabIndex, lastColumnIndex]);

  const hiddenColumnsPlugin = dataTable.current?.__hotInstance?.getPlugin("hiddenColumns");

  const handleTabbarClick = (clickedIndex: number) => {
    setSelectedTabIndex(() => clickedIndex);
  };

  const hideNotification = () => {
    setShiftFailNotification(false);
  };

  useEffect(() => {
    const manualRowMovePlugin = dataTable.current?.__hotInstance?.getPlugin("ManualRowMove");
    if (isTableFiltered) {
      manualRowMovePlugin?.disablePlugin();
    } else {
      manualRowMovePlugin?.enablePlugin();
    }
  }, [isTableFiltered]);

  useEffect(() => {
    if (careUnitId) {
      fetchShiftsAndBudgetData();
    }
  }, [careUnitId, scenario]);

  const fetchShiftsAndBudgetData = () => {
    let newShiftsData: WorkShiftsTableData[] = [];

    ShiftService.getShifts(careUnitId, scenario)
      .then((res: AxiosResponse<ShiftsDataResponse[]>) => {
        const shiftsData: ShiftsDataResponse[] = res.data;

        ShiftService.getShiftsBudgetResult(careUnitId, scenario)
          .then((res: AxiosResponse<TotalBudgetDataResponse>) => {
            const budgetData: TotalBudgetDataResponse | undefined = res.data;

            newShiftsData = shiftsData.map((shiftData: ShiftsDataResponse) => {
              const associatedBudget: ShiftsBudgetDataResponse | undefined = budgetData?.shiftsBudgets.find(
                (budget: ShiftsBudgetDataResponse) => budget.id === shiftData.id
              );

              return {
                ...shiftData,
                totalCost: (associatedBudget ? associatedBudget.totalCost : 0) / 1000,
                totalFte: associatedBudget ? associatedBudget.totalFte : 0,
                weekendShifts: associatedBudget ? associatedBudget.weekendShifts : 0,
                fteWeekendShifts: associatedBudget ? associatedBudget.fteWeekendShifts : 0,
              };
            });

            newShiftsData.push({
              id: "total_row",
              unit: translate("total"),
              totalCost: budgetData ? budgetData.totalCost / 1000 : 0,
              totalFte: budgetData ? budgetData.totalFte : 0,
              weekendShifts: budgetData ? budgetData.totalWeekendShifts : 0,
              fteWeekendShifts: budgetData ? budgetData.totalFteWeekendShifts : 0,
            } as WorkShiftsTableData);

            setShiftsTotals({
              cost: budgetData ? budgetData.totalCost / 1000 : 0,
              fte: budgetData ? budgetData.totalFte : 0,
              weekend_shifts: budgetData ? budgetData.totalWeekendShifts : 0,
              fte_weekend_shifts: budgetData ? budgetData.totalFteWeekendShifts : 0,
            });

            setWorkShiftsTableData(() => newShiftsData);
          })
          .catch((err: AxiosError<BudgetDataErrorResponse>) => {
            newShiftsData = shiftsData.map((shiftData: ShiftsDataResponse) => {
              return {
                ...shiftData,
                totalCost: 0,
                totalFte: 0,
                weekendShifts: 0,
                fteWeekendShifts: 0,
              };
            });

            if (newShiftsData.length !== 0) {
              newShiftsData.push({
                id: "total_row",
                unit: translate("total"),
                totalCost: 0,
                totalFte: 0,
                weekendShifts: 0,
                fteWeekendShifts: 0,
              } as WorkShiftsTableData);
            }
            setWorkShiftsTableData(newShiftsData);
            console.error(err.response?.data.ErrorMessage);
          });
      })
      .catch((err: AxiosError) => {
        console.error(err.message);
      });
  };

  const rowOrderList: any[] = [];

  const saveShifts = async () => {
    setIsSaveButtonDisabled(true);

    const totalRowRemovedTableData = tableData.filter((row) => row.id !== "total_row");

    const isValidData = validators.competenceAndProductionsSharesSumValidator(
      totalRowRemovedTableData,
      dynamicCompetencesNamesRef.current,
      productionShareColumns
    );

    if (!isValidData) {
      setIsValidProductionAndCompetenceShares(false);

      setTimeout(() => {
        setIsValidProductionAndCompetenceShares(true);
      }, 5000);
      return;
    }

    const dataWithAllocations = utitlies.transformCompetenceAllocations(
      totalRowRemovedTableData,
      dynamicCompetencesNamesRef
    );

    if (!isTableFiltered) {
      for (let i: number | undefined = 0; i < dataWithAllocations.length; i++) {
        rowOrderList[i] = dataTable.current?.__hotInstance?.rowIndexMapper?.getVisualFromPhysicalIndex(i);
      }
    }

    await ShiftService.saveShifts(dataWithAllocations, careUnitId, rowOrderList, scenario)
      .then(() => {
        if (careUnitId) {
          setLoading(true);
          ResultService.executeAlgoAsync(careUnitId, "core", scenario)
            .then(() => {
              setLoading(false);
              setAlgoExeSuccessNotification(true);
              allocationsHandler.fetch();
              fetchShiftsAndBudgetData();
            })
            .catch((error) => {
              setLoading(false);
              setAlgoExeFailNotification(error.response.data.ErrorMessage);
            });
        }
      })
      .catch((err) => {
        setShiftFailNotification(true);
        setIsSaveButtonDisabled(false);
        setTimeout(hideNotification, 3000);
        throw err;
      });

    resetTableEditedStates();
  };

  const resetTableEditedStates = () => {
    setIsTableDataEdited(false);
    setIsSaveButtonDisabled(true);
    setIsSaveChangesNotificationOpen(false);
  };

  const handleOnSaveClick = () => {
    saveShifts();
  };

  const SHIFT_COLUMN_WIDTHS = "column_widths_shifts";

  const createNestedHeaders = () => {
    const formalCompetenceColumnspan: FormalCompetenceColumnSpan[] = [];

    formalCompetenceDataHandler.data.forEach((formalCompetence) => {
      formalCompetenceColumnspan.push({
        formalCompetenceId: formalCompetence.id as number,
        colspan: formalCompetence.workCompetences.length,
        formalCompetenceName: formalCompetence.name,
      });
    });

    const competenceMixHeaders = formalCompetenceColumnspan.map((fc) => {
      return {
        colspan: fc.colspan,
        label: fc.formalCompetenceName || "",
      };
    });

    const firstLayer = [
      {
        colspan: 2,
        label: translate(""),
      },
      {
        colspan: 11,
        label: translate("Presence"),
      },
      {
        colspan: 6,
        label: translate("properties"),
      },
      ...competenceMixHeaders,
      {
        colspan: 8,
        label: translate("type_of_production"),
      },
      {
        colspan: 52,
        label: translate("weekly_availability"),
      },
      {
        colspan: 2,
        label: translate("resource_requirements"),
      },
      {
        colspan: 2,
        label: translate("weekend_requirements"),
      },
    ];

    const secondLayer = colConfigs.map((col) => {
      return {
        label: translate(col.headerTitle),
        colspan: 1,
      };
    });

    setNestedHeaders([firstLayer, secondLayer]);
  };

  useEffect(() => {
    createNestedHeaders();
  }, [colConfigs]);

  const handleRightButtonClick = () => {
    blocker.proceed && blocker.proceed();
  };

  const handleLeftButtonClick = () => {
    saveShifts();
    setIsSaveChangesNotificationOpen(false);
    blocker.reset && blocker.reset();
  };

  return (
    <GridLayout additionalStyles="mt-0 px-0">
      <SaveChangesConfirmation
        isSaveChangesNotificationOpen={isSaveChangesNotificationOpen}
        onLeftButtonClick={handleLeftButtonClick}
        onRightButtonClick={handleRightButtonClick}
      />
      <div className="absolute right-8 z-10 lg:top-12 xl:top-16">
        {shiftFailNotification && (
          <NotificationFloat varaint="error" content={`${translate("something_went_wrong")}`} />
        )}
        {!isValidProductionAndCompetenceShares && (
          <NotificationFloat varaint="error" content={translate("workshifts_calculation_error_prodcompetence")} />
        )}
        {loading && <NotificationFloat varaint="loading" content={translate("saving_and_running_calculations")} />}

        {algoExeSuccessNotification && <NotificationFloat varaint="success" content={translate("data_saved")} />}

        {algoExeFailNotification !== "" && <NotificationFloat varaint="error" content={algoExeFailNotification} />}
      </div>
      <div className="col-start-1 col-end-13">
        <div className="mt-0 flex items-center justify-between px-2">
          <TabbarTeritary tabs={tabs} onClick={handleTabbarClick} />
          {!isCareUnitReadOnly && (
            <div className="my-2">
              <Button
                onClick={handleOnSaveClick}
                disabled={isSaveButtonDisabled}
                text={translate("save_changes")}
                variant={"primary"}
                additionalStyles={"xl:w-[12.5rem] xl:h-[2.5rem] lg:h-[2.25rem] lg:w-[10.313rem]"}
              />
            </div>
          )}
        </div>
        <div className="mt-0 bg-[#F8F8F8] px-2 pt-0 lg:h-[79vh] xl:h-[80vh]">
          <div className="relative lg:h-[79vh] xl:h-[80vh]">
            <div className="mt-0 overflow-y-scroll bg-[#F8F8F8] px-2 pt-0 lg:h-[79vh] xl:h-[80vh]">
              <HotTable
                ref={dataTable}
                language={
                  localStorage.getItem("i18nextLng") === "en" || localStorage.getItem("i18nextLng") === "en-US"
                    ? "en-US"
                    : "sv-SE"
                }
                data={tableData}
                dataSchema={workshiftDataSchema}
                columns={colConfigs.map((col) => col.meta)}
                licenseKey="non-commercial-and-evaluation"
                contextMenu={
                  isCareUnitReadOnly === false
                    ? {
                        items: {
                          row_above: {},

                          remove_row: {
                            disabled: function () {
                              const [startRow, , endRow] = this.getSelectedLast() as number[];

                              const lastRow = this.countRows() - 1;

                              if (startRow === lastRow || endRow === lastRow) {
                                return true;
                              } else {
                                return false;
                              }
                            },
                          },
                          row_below: {
                            disabled: function () {
                              const [startRow, , endRow] = this.getSelectedLast() as number[];

                              const lastRow = this.countRows() - 1;

                              if (endRow > 0) {
                                if (startRow === lastRow || endRow === lastRow) {
                                  return true;
                                } else {
                                  return false;
                                }
                              } else {
                                return false;
                              }
                            },
                          },
                          alignment: {},
                          copy: {},
                          cut: {},
                        },
                      }
                    : ["alignment", "copy"]
                }
                height="79vh"
                fixedColumnsLeft={2}
                afterGetColHeader={(col: number, TH: HTMLElement) => {
                  const colConfig = colConfigs[col];

                  adjustColumnHeaderColour(col, TH, colConfig);
                }}
                nestedHeaders={nestedHeaders}
                colWidths={colConfigs.map((col) => col.width)}
                afterColumnResize={(newSize, col) => {
                  let newColumnsWidths: any = localStorage.getItem(SHIFT_COLUMN_WIDTHS)
                    ? JSON.parse(localStorage.getItem(SHIFT_COLUMN_WIDTHS) as string)
                    : {};
                  newColumnsWidths = {
                    ...newColumnsWidths,
                    [col]: newSize,
                  };

                  localStorage.setItem(SHIFT_COLUMN_WIDTHS, JSON.stringify(newColumnsWidths));
                }}
                modifyColWidth={(width, col) => {
                  const newColumnsWidths: any = localStorage.getItem(SHIFT_COLUMN_WIDTHS)
                    ? JSON.parse(localStorage.getItem(SHIFT_COLUMN_WIDTHS) as string)
                    : {};

                  if (newColumnsWidths[col]) {
                    return newColumnsWidths[col];
                  }

                  return width;
                }}
                rowHeaders={true}
                rowHeaderWidth={30}
                hiddenColumns={{
                  columns: hiddenColumns,
                }}
                beforePaste={(data, coords) => {
                  if (coords[0].startRow + (data.length - 1) >= tableData.length - 1) {
                    // When pasting, if this condition is getting triggers,
                    // that means the last row is overlapping. So we should remove
                    // total row from dataset

                    setTableData((prevState) => {
                      const rowsWithoutLastRow = prevState.slice(0, -1);
                      return [...rowsWithoutLastRow];
                    });
                  }
                }}
                rowHeights={35}
                wordWrap={false}
                cells={(row, column) => {
                  type cp = {
                    className?: string;
                    readOnly?: boolean;
                    editor?: boolean | string;
                  };

                  const cp: cp = {};

                  //  get visual row index
                  const visualRow = Number(
                    dataTable.current?.__hotInstance?.rowIndexMapper?.getVisualFromPhysicalIndex(row)
                  );

                  //  get visual column index
                  const visualColumn = Number(
                    dataTable.current?.__hotInstance?.columnIndexMapper?.getVisualFromPhysicalIndex(column)
                  );

                  const value = dataTable.current?.__hotInstance?.getDataAtCell(visualRow, visualColumn);

                  if (column === 14 || column === 17 || column === 18) {
                    cp.className = "htCenter htMiddle";
                  }

                  if (column === 2 || column === 3 || column === 4) {
                    cp.className = "htRight";
                  }

                  if (visualRow % 2 === 0) {
                    if (cp.className) {
                      cp.className = cp.className + " grey-row";
                    } else {
                      cp.className = "grey-row";
                    }
                  }

                  if (value === 0) {
                    if (cp.className) {
                      cp.className = cp.className + " grey-out-font-color";
                    } else {
                      cp.className = "grey-out-font-color";
                    }
                  } else {
                    if (cp.className) {
                      cp.className = cp.className + " default-font-color";
                    } else {
                      cp.className = "default-font-color";
                    }
                  }

                  if ([96, 97, 98, 99].includes(column)) {
                    cp.readOnly = true;
                  }

                  const noOfRows = dataTable.current?.hotInstance?.countRows() || 0;
                  const isLastRow = noOfRows - 1;

                  if (row === isLastRow) {
                    cp.editor = false;
                    cp.className = cp.className + " activity-result-sub-total";
                  } else {
                    cp.editor = colConfigs[column]?.meta?.type || "text";
                  }

                  if (cp.className) {
                    cp.className = cp.className + " htMiddle";
                  }

                  return cp;
                }}
                columnSorting={true}
                manualRowMove={true}
                dropdownMenu={["filter_by_condition", "filter_action_bar", "filter_by_value"]}
                filters={true}
                beforeFilter={function () {
                  for (let i: number | undefined = 0; i < tableData.length; i++) {
                    rowOrderList[i] = dataTable.current?.__hotInstance?.rowIndexMapper?.getVisualFromPhysicalIndex(i);
                  }
                }}
                afterFilter={function (conditionStack) {
                  if (conditionStack.length > 0) {
                    setIsTableFiltered(true);
                  } else {
                    setIsTableFiltered(false);
                  }
                }}
                beforeChange={function (changes, source) {
                  for (const change of changes) {
                    if (change && changes && changes.length > 0 && changes[0] && changes[0].length > 1) {
                      const row = change[0];
                      const newValue = change[3];

                      const columnIndex = dataTable.current?.__hotInstance?.propToCol(changes[0][1] as number) || 0;
                      const cellProperties = dataTable.current?.__hotInstance?.getCellMeta(row, columnIndex as number);
                      /*
                    To convert precentages to number - If user enters 100%
                    then it will be entered to the table as 1
                  */

                      if (newValue?.toString() === "" || newValue?.toString() === " ") {
                        change[3] = null;
                      }

                      if (newValue?.toString() === "TRUE" || newValue?.toString() === "true") {
                        change[3] = true;
                      }

                      if (newValue?.toString() === "FALSE" || newValue?.toString() === "false") {
                        change[3] = false;
                      }

                      if (cellProperties?.type === "numeric" && newValue?.toString().endsWith("%")) {
                        const parsedValue = parseFloat(newValue) / 100.0;
                        change[3] = parsedValue;
                      }

                      if (source !== "CopyPaste.paste" && cellProperties?.type === "time") {
                        const [hours, minutes] = newValue.includes(":")
                          ? newValue.split(":")
                          : [newValue.slice(0, 2), newValue.slice(2)];

                        const date = new Date();
                        if (hours) {
                          date.setHours(parseInt(hours, 10));
                        } else {
                          date.setHours(parseInt("00", 10));
                        }
                        if (minutes) {
                          date.setMinutes(parseInt(minutes, 10));
                        } else {
                          date.setMinutes(parseInt("00", 10));
                        }
                        const formattedTime = `${String(date.getHours()).padStart(2, "0")}:${String(
                          date.getMinutes()
                        ).padStart(2, "0")}`;
                        change[3] = formattedTime;
                      }
                    }
                  }
                }}
                beforeCreateRow={(index, amount, source) => {
                  const rowCount = dataTable.current?.__hotInstance?.countRows();

                  if (rowCount && source === "ContextMenu.rowBelow" && index === rowCount - 1) {
                    // remove last row
                    dataTable.current?.__hotInstance?.alter("remove_row", rowCount - 1);
                  }
                }}
                afterCreateRow={(index, amount, source) => {
                  if (source === "ContextMenu.rowBelow") {
                    const newTableData = tableData;

                    const isTotalRowExists = tableData.find((row) => row.id === "total_row");

                    if (!isTotalRowExists) {
                      setTableData([
                        ...newTableData,
                        {
                          id: "total_row",
                          unit: translate("total"),
                          totalCost: shiftsTotals.cost,
                          totalFte: shiftsTotals.fte,
                          weekendShifts: shiftsTotals.weekend_shifts,
                          fteWeekendShifts: shiftsTotals.fte_weekend_shifts,
                        } as DynamicType,
                      ]);
                    }
                  }

                  setIsSaveButtonDisabled(false);
                }}
                afterRemoveRow={() => {
                  const newTableData = tableData;

                  const totalShifts = tableData
                    .filter((row) => row.id !== "total_row")
                    .reduce(
                      (
                        acc: {
                          totalCost: number;
                          totalFte: number;
                          weekendShifts: number;
                          fteWeekendShifts: number;
                        },
                        row
                      ) => {
                        acc.totalCost += Number(row.totalCost);
                        acc.totalFte += Number(row.totalFte);
                        acc.weekendShifts += Number(row.weekendShifts);
                        acc.fteWeekendShifts += Number(row.fteWeekendShifts);
                        return acc;
                      },
                      {
                        totalCost: 0,
                        totalFte: 0,
                        weekendShifts: 0,
                        fteWeekendShifts: 0,
                      }
                    );

                  setShiftsTotals({
                    cost: totalShifts.totalCost,
                    fte: totalShifts.totalFte,
                    weekend_shifts: totalShifts.weekendShifts,
                    fte_weekend_shifts: totalShifts.fteWeekendShifts,
                  });

                  const updateTableData = tableData.map((row) =>
                    row.id === "total_row"
                      ? {
                          ...row,
                          totalCost: totalShifts.totalCost,
                          totalFte: totalShifts.totalFte,
                          weekendShifts: totalShifts.weekendShifts,
                          fteWeekendShifts: totalShifts.fteWeekendShifts,
                        }
                      : row
                  );

                  setTableData(updateTableData as DynamicType[]);
                  if (newTableData.length === 1) {
                    if (newTableData[0].id === "total_row") {
                      setTableData([]);
                    }
                  }

                  setIsSaveButtonDisabled(false);
                }}
                afterRowMove={() => {
                  setIsSaveButtonDisabled(false);
                }}
                afterSelection={(row, column) => {
                  dataTable.current?.__hotInstance?.batch(() => {
                    const selectedCell = dataTable.current?.__hotInstance?.getCell(row, column)?.innerText;
                    if (selectedCell !== null && selectedCell?.toString().endsWith("%")) {
                      setIsPercentageMarkAtTheEnd(true);
                    } else {
                      setIsPercentageMarkAtTheEnd(false);
                    }
                  });
                }}
                afterChange={(changes, source) => {
                  if (source === "CopyPaste.paste") {
                    const newTableData = tableData;

                    const isTotalRowExists = tableData.find((row) => row.id === "total_row");

                    if (!isTotalRowExists) {
                      newTableData.push({
                        id: "total_row",
                        unit: translate("total"),
                        totalCost: shiftsTotals.cost,
                        totalFte: shiftsTotals.fte,
                        weekendShifts: shiftsTotals.weekend_shifts,
                        fteWeekendShifts: shiftsTotals.fte_weekend_shifts,
                      } as DynamicType);

                      dataTable.current?.__hotInstance?.updateSettings({
                        data: newTableData,
                      });
                    }
                  }

                  if (source === "edit" || source === "Autofill.fill" || source === "CopyPaste.paste") {
                    dataTable.current?.hotInstance?.validateCells((valid) => {
                      if (valid) {
                        setIsSaveButtonDisabled(false);
                        setIsTableDataEdited(true);
                      }
                    });
                  }
                }}
                manualColumnResize={true}
                afterBeginEditing={() => {
                  if (isPercentageMarkAtTheEnd === true) {
                    // eslint-disable-next-line prefer-const
                    let currentEditor = dataTable.current?.hotInstance?.getActiveEditor() as any;

                    currentEditor.TEXTAREA.value = "%";
                    currentEditor.TEXTAREA.setSelectionRange(0, 0);
                    currentEditor.TEXTAREA.focus();
                    setIsPercentageMarkAtTheEnd(false);
                  }
                }}
              />
            </div>

            <DataTableAddButton dataTable={dataTable} />
          </div>
        </div>
      </div>
    </GridLayout>
  );
};

export default ShiftsHome;
