import React from "react";
import clsx from "clsx";
import { ChatMessageProps } from "../../../interfaces/props/chatMessageProps";

const ChatMessage = ({ message }: ChatMessageProps): JSX.Element => {
  return (
    <div
      className={clsx(
        "mb-2 rounded-lg p-2 text-sm",
        message.isUser ? "ml-auto bg-chat-message-blue text-white" : "mr-auto bg-gray-200"
      )}
      style={{ maxWidth: "80%" }}
    >
      {message.text}
    </div>
  );
};

export default ChatMessage;
