import { useState, useCallback } from "react";
import { ChatMessage } from "../../../interfaces/chat/ChatMessage";

export function useChatMessages(initialMessages: ChatMessage[] = []) {
  const [messages, setMessages] = useState<ChatMessage[]>(initialMessages);

  const addMessage = useCallback((text: string, isUser: boolean) => {
    const newMessage: ChatMessage = {
      id: Date.now(),
      text,
      isUser,
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);
  }, []);

  const addUserMessage = useCallback(
    (text: string) => {
      addMessage(text, true);
    },
    [addMessage]
  );

  const addAiMessage = useCallback(
    (text: string) => {
      addMessage(text, false);
    },
    [addMessage]
  );

  const resetMessages = useCallback(() => {
    setMessages(initialMessages);
  }, [initialMessages]);

  return {
    messages,
    addUserMessage,
    addAiMessage,
    resetMessages,
  };
}
