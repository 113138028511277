import { useEffect, useState } from "react";
import OutpatientProductivityService from "../../../../services/outpatientProductivityService";
import { OutpatientProductivityAnalysis } from "../../../../interfaces/analytics/orgOverview/outpatientProductivityAnalysis";
import { ReturnGetProductivityAnalysisData } from "../../../../interfaces/analytics/orgOverview/returnGeProductivityAnalysisData";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { CareUnitTypeEnum } from "../../../../utils/constants/careUnitTypes";

const useGetOutpatientProdAnalysisData = (
  scenarioId: number,
  year: number
): ReturnGetProductivityAnalysisData<OutpatientProductivityAnalysis[]> => {
  const selectedCareUnits = useSelector((state: RootState) => state.globalFilter.filterCareUnits.units);
  const selectedCareUnitType = useSelector((state: RootState) => state.globalFilter.filterCareUnitType);

  const [productivityAnalysisData, setProductivityAnalysisData] = useState<OutpatientProductivityAnalysis[]>([]);

  const fetchData = async () => {
    await OutpatientProductivityService.getProductivityAnalysis(scenarioId, year, selectedCareUnits)
      .then((res) => {
        const responseData: OutpatientProductivityAnalysis[] = res.data;
        setProductivityAnalysisData(responseData);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (
      (selectedCareUnits.length === 1 && selectedCareUnitType === CareUnitTypeEnum.OUTPATIENT) ||
      selectedCareUnitType === null
    ) {
      fetchData();
    }
  }, [scenarioId, year, selectedCareUnits, selectedCareUnitType]);

  return {
    data: productivityAnalysisData,
    fetch: fetchData,
  };
};

export default useGetOutpatientProdAnalysisData;
