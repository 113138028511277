import { AxiosResponse } from "axios";
import HttpCommonService from "./httpCommonService";
import { ChatGptRequest } from "../interfaces/chatGpt/requests/ChatGptRequest";

export const ChatGptService = {
  postChatMessage: async (data: ChatGptRequest): Promise<AxiosResponse<string>> => {
    return await HttpCommonService.postAsync("api/chatgpt", data)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  // You can add more methods here as needed
};

export default ChatGptService;
