import { useEffect, useState } from "react";
import { ReturnGetProductivityAnalysisData } from "../../../../interfaces/analytics/orgOverview/returnGeProductivityAnalysisData";
import { CareLevel } from "../../../../interfaces/analytics/orgOverview/careLevel";
import careLevelService from "../../../../services/careLevelService";

const useGetCareLevelTargetGridData = (): ReturnGetProductivityAnalysisData<CareLevel[]> => {
  const [careLevelData, setCareLevelData] = useState<CareLevel[]>([]);

  const fetchData = async () => {
    await careLevelService
      .getCareLevels()
      .then((res) => {
        setCareLevelData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    data: careLevelData,
    fetch: fetchData,
  };
};

export default useGetCareLevelTargetGridData;
