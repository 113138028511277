import React, { useEffect, useRef, useState } from "react";
import { HotTableClass as HotTable } from "@handsontable/react";
import { InpatientProductivityAnalysis } from "../../../../../interfaces/analytics/orgOverview/inpatientProductivityAnalysis";
import { DataTableProps } from "../../../../../interfaces/props/data-table-props";
import useOverviewGridConfigs from "./hooks/useOverviewGridConfigs";

const OverviewGrid = ({ data }: { data: InpatientProductivityAnalysis[] }) => {
  const dataTable = useRef<HotTable>(null);
  const tableConfiguration = useOverviewGridConfigs(dataTable);

  const [tableProps, setTableProps] = useState<DataTableProps>({
    ref: dataTable,
  });

  const [tableData, setTableData] = useState<InpatientProductivityAnalysis[]>([]);

  useEffect(() => {
    const tableConfigs = tableConfiguration.getConfigs();
    setTableProps(tableConfigs);
  }, [tableData]);

  useEffect(() => {
    setTableData(data);
  }, [data]);

  return (
    <>
      <HotTable data={tableData} {...tableProps} licenseKey="non-commercial-and-evaluation" />
    </>
  );
};

export default OverviewGrid;
