import { useEffect, useState } from "react";
import { InpatientProductivityAnalysis } from "../../../../interfaces/analytics/orgOverview/inpatientProductivityAnalysis";
import InpatientProductivityService from "../../../../services/inpatientProductivityService";
import { ReturnGetProductivityAnalysisData } from "../../../../interfaces/analytics/orgOverview/returnGeProductivityAnalysisData";
import { useFeatureFlagIsEnabled } from "../../../../hooks/useFeatureFlagIsEnabled";
import { FeatureFlags } from "../../../../utils/constants/featureFlags";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { CareUnitTypeEnum } from "../../../../utils/constants/careUnitTypes";

const useGetInpatientProdAnalysisData = (
  careProviderId: number | null,
  scenarioId: number,
  year: number,
  selectedCareLevels: number[] | null
): ReturnGetProductivityAnalysisData<InpatientProductivityAnalysis[]> => {
  const isOrgOverviewFilterEnabled = useFeatureFlagIsEnabled(FeatureFlags.OrgOverviewFilter);
  const selectedCareUnits = useSelector((state: RootState) => state.globalFilter.filterCareUnits.units);
  const selectedCareUnitType = useSelector((state: RootState) => state.globalFilter.filterCareUnitType);

  const [productivityAnalysisData, setProductivityAnalysisData] = useState<InpatientProductivityAnalysis[]>([]);

  const fetchAnalysisData = async () => {
    if (careProviderId) {
      await InpatientProductivityService.getProductivityAnalysis(careProviderId, scenarioId, year)
        .then((res) => {
          const responseData: InpatientProductivityAnalysis[] = res.data;
          setProductivityAnalysisData(responseData);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const fetchFilteredAnalysisData = async () => {
    if (selectedCareUnits.length > 0 && selectedCareLevels) {
      await InpatientProductivityService.getProductivityAnalysisByCareUnit(
        scenarioId,
        year,
        selectedCareUnits,
        selectedCareLevels[0] === -1 ? [] : selectedCareLevels
      )
        .then((res) => {
          const responseData: InpatientProductivityAnalysis[] = res.data;
          setProductivityAnalysisData(responseData);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  useEffect(() => {
    if (!isOrgOverviewFilterEnabled) {
      fetchAnalysisData();
    }
  }, [careProviderId, scenarioId, year]);

  useEffect(() => {
    if (
      isOrgOverviewFilterEnabled &&
      ((selectedCareUnits.length === 1 && selectedCareUnitType === CareUnitTypeEnum.INPATIENT) ||
        selectedCareUnitType === null)
    ) {
      fetchFilteredAnalysisData();
    }
  }, [selectedCareUnits, scenarioId, year, selectedCareLevels, selectedCareUnitType]);

  return {
    data: productivityAnalysisData,
    fetch: isOrgOverviewFilterEnabled ? fetchFilteredAnalysisData : fetchAnalysisData,
  };
};

export default useGetInpatientProdAnalysisData;
