import { useEffect, useState } from "react";
import { PhysicianUnitsProductivityComparisonData } from "../../../../../../interfaces/productivity/physicianUnitsProductivityComparisonData";

const usePhysicianUnitsComparisonPrompt = (
  isFeatureFlagEnabled: boolean,
  data: PhysicianUnitsProductivityComparisonData | null
) => {
  const [prompt, setPrompt] = useState<string | null>(null);

  const intro =
    'Läkarenheten som ska analyseras har följande plan jämfört med referensgruppen. Om något värde är "NA" i en kategori så utgår den kategorin ur analysen. Per kategori ser skillnaderna ut så här:';

  useEffect(() => {
    if (data) {
      const lines = [
        isFeatureFlagEnabled && `"Arbetstimmar per akutmottagningsbesök", Läkarenhet 1,5, Referensgrupp 1,0`,
        `"Läkarkontakter per dag", Läkarenhet ${data.dailyOutpatientVisitsPerFTE}, Referensgrupp ${data.restDailyOutpatientVisitsPerFTEAvr}`,
        `"Arbetstimmar per vårddygn", Läkarenhet ${data.workingHoursPerInpatientDay * 60}, Referensgrupp ${
          data.restWorkingHoursPerInpatientDayAvr * 60
        }`,
        isFeatureFlagEnabled && `"Arbetstimmar per operation", Läkarenhet NA, Referensgrupp NA`,
        isFeatureFlagEnabled && `"Arbetstimmar per operationstid", Läkarenhet NA, Referensgrupp NA`,
        `"Andel produktionstid", Läkarenhet ${data.productionTimePercentage}%, Referensgrupp ${data.restProductionTimePercentage}%`,
        `"Andel konsultativt stöd", Läkarenhet ${data.consultingTimePercentage}%, Referensgrupp ${data.restConsultingTimePercentage}%`,
        `"Andel utbildning", Läkarenhet ${data.learningDevelopmentTimePercentage}%, Referensgrupp ${data.restLearningDevelopmentTimePercentage}%`,
        `"Andel icke klinisk tid", Läkarenhet ${data.nonClinicalTimePercentage}%, Referensgrupp ${data.restNonClinicalTimePercentage}%`,
        `"Andel ledningstid" Läkarenhet ${data.managementTimePercentage}%, Referensgrupp ${data.restManagementTimePercentage}%`,
      ]
        .filter(Boolean)
        .join("");

      setPrompt(`${intro}${lines}`);
    }
  }, [data, isFeatureFlagEnabled]);

  return prompt;
};

export default usePhysicianUnitsComparisonPrompt;
