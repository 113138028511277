import React, { useRef, useEffect } from "react";
import { ChatPanelProps } from "../../interfaces/props/chatPanelProps";
import ChatMessage from "./ChatMessage/ChatMessage";

function ChatPanel<T>({ messages }: ChatPanelProps<T>) {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className="flex h-full flex-col">
      <div className="flex-grow overflow-y-auto p-4">
        {messages.map((message) => (
          <ChatMessage key={message.id} message={message} />
        ))}
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
}

export default ChatPanel;
