import React, { useCallback, useEffect } from "react";
import ChatPanel from "../../../../../components/ChatPanel/ChatPanel";
import ChatGptService from "../../../../../services/chatGptService";
import { useChatMessages } from "../../../../../common/hooks/chat/useChatMessages";
import usePhysicianUnitComparisonData from "./hooks/usePhysicianUnitsProductivityComparisonData";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";
import { FeatureFlags } from "../../../../../utils/constants/featureFlags";
import { useFeatureFlagIsEnabled } from "../../../../../hooks/useFeatureFlagIsEnabled";
import usePhysicianUnitsComparisonPrompt from "./hooks/usePhysicianUnitsComparisonPrompt";

interface ProductivityChatProps {
  isPanelOpen: boolean;
}

const ProductivityChat = ({ isPanelOpen }: ProductivityChatProps): JSX.Element => {
  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);
  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);
  const year = useSelector((state: RootState) => state.globalFilter.filterYear);
  const isFeatureFlagEnabled = useFeatureFlagIsEnabled(FeatureFlags.ChatGptDemo);

  const { data } = usePhysicianUnitComparisonData(careUnitId as number, scenario, year);

  const prompt = usePhysicianUnitsComparisonPrompt(isFeatureFlagEnabled, data);

  const { messages, addUserMessage, addAiMessage } = useChatMessages([]);

  const handleSendMessage = useCallback(
    (message: string) => {
      addUserMessage(message);
    },
    [addUserMessage]
  );

  useEffect(() => {
    if (prompt) {
      addUserMessage(prompt);
    }
  }, [prompt]);

  useEffect(() => {
    const handlePanelOpen = async () => {
      if (messages.length > 0 && messages[messages.length - 1].isUser) {
        try {
          const response = await ChatGptService.postChatMessage({
            message: messages[messages.length - 1].text,
          });
          addAiMessage(response.data);
        } catch (error) {
          console.error("Error sending message to ChatGPT:", error);
          addAiMessage("Sorry, there was an error processing your request.");
        }
      }
    };

    if (isPanelOpen) {
      handlePanelOpen();
    }
  }, [isPanelOpen, messages, addAiMessage]);

  return <ChatPanel messages={messages} onSendMessage={handleSendMessage} />;
};

export default ProductivityChat;
