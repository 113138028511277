import { AxiosResponse } from "axios";
import { ActivityAnalysisProductionPayLoad } from "../interfaces/analytics/activity-analysis-production";
import HttpCommonService from "./httpCommonService";

import { ActivityAnalysisShift } from "../interfaces/analytics/activity-analysis-shift";
import { ActivityAnalysisDimensionsRequestSchema } from "../interfaces/analytics/activity-analysis-dimensions-schema";

export const ActivityAnalysisService = {
  getActivityAnalysisProduction: async (
    careUnitId: number,
    scenarioId: number,
    year: number
  ): Promise<AxiosResponse<ActivityAnalysisProductionPayLoad[]>> => {
    return await HttpCommonService.getAsync(`api/activity-analysis/productions/${careUnitId}/${year}/${scenarioId}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getActivityAnalysisShift: async (
    careUnitId: number,
    scenarioId: number,
    year: number,
    isWithinShift: boolean
  ): Promise<AxiosResponse<ActivityAnalysisShift[]>> => {
    return await HttpCommonService.getAsync(
      `api/activity-analysis/shifts/${careUnitId}/${scenarioId}/${year}/${isWithinShift}`
    )
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getActivityAnalysisResults: async (id: number | null, year: number, scenario: number) => {
    return await HttpCommonService.getAsync(`api/activity-analysis/reults/${id}/${scenario}/${year}`)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  postActivityAnalysisProduction: async (
    careUnitId: number,
    scenarioId: number,
    year: number,
    data: ActivityAnalysisProductionPayLoad[]
  ) => {
    const payload = {
      activityAnalysisProductions: data,
      careUnitId: careUnitId,
      scenarioId: scenarioId,
      year: year,
    };

    return await HttpCommonService.postAsync(`api/activity-analysis/productions`, payload)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  postActivityAnalysisShift: async (
    careUnitId: number,
    scenarioId: number,
    year: number,
    isWithinShift: boolean,
    data: ActivityAnalysisShift[]
  ) => {
    const payload = {
      careUnitId: careUnitId,
      scenarioId: scenarioId,
      year: year,
      isWithinShifts: isWithinShift,
      activityAnalysisShifts: data,
    };

    return await HttpCommonService.postAsync("api/activity-analysis/shifts", payload)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  getDimensionsForFilters: async (careUnitId: number): Promise<ActivityAnalysisDimensionsRequestSchema> => {
    return await HttpCommonService.getAsync(`api/outpatientdetailplans/diemensions/${careUnitId}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getProductionPlanForFilterGroup: async (
    careUnitId: number,
    filterGroupId: number,
    scenarioId: number,
    year: number
  ): Promise<number> => {
    return await HttpCommonService.getAsync(
      `api/activity-analysis/production-plan/${careUnitId}/${filterGroupId}/${scenarioId}/${year}`
    )
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },
};

export default ActivityAnalysisService;
