import React, { useEffect, useState } from "react";
import ProductionFilters from "./Filter/ProductionFilters";
import WaterfallKpis from "./KPI/WaterfallKpis";
import { CARE_UNIT_TYPES } from "../../../../utils/constants/careUnitTypes";
import { PhysicianSubUnits } from "../../../../components";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { FteTotalChartData, TotalProductivityFteResponse } from "../../../../interfaces/productivity";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useTranslation } from "react-i18next";
import { ProductivityService } from "../../../../services";
import { AxiosResponse } from "axios";
import { ISelectOption } from "../../../../interfaces";
import { productionShares, productionSharesColors } from "../../../../utils/highcharts/productivity/constants";
import { useNumberFormat } from "../../../../hooks/use-number-format";
import LoadingWithGrey from "../../../../components/loading/LoadingWithGrey";
import DraggableResizable from "../../../../components/DraggableResizable/DraggableResizable";
import ProductivityChat from "./ProductivityChat/ProductivityChat";

export interface formattedData {
  color: string;
  name: string;
  y?: number;
  isIntermediateSum?: boolean;
  isSum?: boolean;
}

interface ProductionWaterfallProps {
  isPanelOpen: boolean;
  onTogglePanel: () => void;
}

const ProductionWaterfall: React.FC<ProductionWaterfallProps> = ({ isPanelOpen, onTogglePanel }) => {
  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);
  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);
  const careUnitTypeId = useSelector((state: RootState) => state.globalFilter.filterCareUnitType);

  const { t: translate, i18n } = useTranslation();
  const { localize, round } = useNumberFormat();

  const typesOfInputs: ISelectOption[] = [
    {
      label: translate("fte"),
      value: "fte",
    },
    {
      label: translate("cost") + " (kSEK)",
      value: "cost",
    },
    {
      label: translate("working_hours"),
      value: "working_hours",
    },
  ];

  const [totalFteByProduction, setTotalFteByProduction] = useState<FteTotalChartData[] | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedInput, setSelectedInput] = useState<ISelectOption>(typesOfInputs[0]);

  useEffect(() => {
    if (careUnitId !== null && scenario) {
      setLoading(true);
      setTotalFteByProduction([]);
      ProductivityService.getTotalsByProductionShares(selectedInput?.value, careUnitId, scenario).then(
        (res: AxiosResponse<TotalProductivityFteResponse[]>) => {
          const formattedData: formattedData[] = [];

          res.data.forEach((data: TotalProductivityFteResponse) => {
            let hasProductionSumCalculated = false;

            formattedData.forEach((d: formattedData) => {
              if (d.name === data.productionType) {
                hasProductionSumCalculated = true;
              }
            });

            if (!hasProductionSumCalculated) {
              const fteSum = {
                color: productionSharesColors[data.productionType],
                name: data.productionType,
                y: round(
                  res.data.reduce((accumulator, currentElement) => {
                    if (currentElement.productionType === data.productionType) {
                      return accumulator + currentElement.total;
                    }

                    return accumulator;
                  }, 0),
                  selectedInput.value === "working_hours" ? 0 : 1
                ),
              };

              formattedData.push(fteSum);

              if (
                data.productionType === "ProductionShareSurgery" ||
                data.productionType === "ProductionShareEdVisit" ||
                data.productionType === "ProductionShareOutpatientVisit" ||
                data.productionType === "ProductionShareWard"
              ) {
                const intermediateSum = {
                  name: "Total - Direct care",
                  isIntermediateSum: true,
                  color: productionSharesColors["Total - Direct care"],
                };

                formattedData.push(intermediateSum);
              }
            }
          });

          formattedData.sort(
            (a: formattedData, b: formattedData) => productionShares.indexOf(a.name) - productionShares.indexOf(b.name)
          );

          formattedData.push({
            name: "Total",
            isSum: true,
            color: productionSharesColors["Total"],
          });

          const translated = formattedData.map((data: formattedData) => {
            return {
              ...data,
              name: translate(data.name),
            };
          });

          setTotalFteByProduction(translated);
        }
      );

      setLoading(false);
    }
  }, [careUnitId, selectedInput.value, scenario]);

  const options = {
    chart: {
      type: "waterfall",
      style: {
        fontFamily: "Open Sans",
        textOutline: 0,
      },
      backgroundColor: "transparent",
    },

    tooltip: {
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        if (selectedInput.value === "cost") {
          return this.key + "</br>" + localize(Number(this.y), i18n.language) + " K";
        } else {
          return this.key + "</br>" + localize(Number(this.y), i18n.language);
        }
      },
    },

    title: {
      text: undefined,
    },

    xAxis: {
      type: "category",
      labels: {
        style: {
          fontSize: 16,
        },
      },
    },

    yAxis: {
      title: {
        text: translate(selectedInput.value),
        style: {
          fontSize: 16,
        },
      },

      labels: {
        formatter: function (this: Highcharts.AxisLabelsFormatterContextObject): string | number {
          if (selectedInput.value === "cost") {
            return this.value + "K";
          } else {
            return this.value;
          }
        },

        style: {
          fontSize: 16,
        },
      },
    },

    legend: {
      enabled: false,
    },

    series: [
      {
        data: totalFteByProduction,
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          inside: false,
          style: {
            textOutline: 0,
            fontSize: 16,
            fontWeight: "normal",
          },
        },
        pointPadding: 0,
      },
    ],
  };

  const handleTypesOfInputSelect = (selectedOption: ISelectOption) => {
    setSelectedInput(selectedOption);
  };

  return (
    <>
      <div className="h-full w-5/6">
        {!loading ? (
          <HighchartsReact containerProps={{ style: { height: "100%" } }} highcharts={Highcharts} options={options} />
        ) : (
          <div className="flex h-full w-full items-center justify-center">
            <LoadingWithGrey sizeInPixel={40} />
          </div>
        )}
      </div>

      <div className="ml-5 flex  w-1/6 flex-col justify-between">
        <div>
          <ProductionFilters
            handleTypesOfInputSelect={handleTypesOfInputSelect}
            selectedInput={selectedInput}
            typesOfInputs={typesOfInputs}
          />

          <WaterfallKpis />
        </div>
        {careUnitTypeId === CARE_UNIT_TYPES.PHYSICIAN && <PhysicianSubUnits />}
      </div>

      <DraggableResizable isOpen={isPanelOpen} onClose={onTogglePanel} title="Chat">
        <ProductivityChat isPanelOpen={isPanelOpen} />
      </DraggableResizable>
    </>
  );
};

export default ProductionWaterfall;
