import React from "react";

import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTranslation } from "react-i18next";

import { AxisLabelObject } from "../../../../../interfaces/highcharts/AxisLabelObject";
import { useMediaQuery } from "../../../../../hooks";
import { SCREEN_SIZES } from "../../../../../utils/constants/mediaQueries";
import { getChartFontSize } from "../../../../../utils/highcharts/common";
import { ProductionVsPlanProps } from "../../../../../interfaces/analytics/prodVsPlan/prodVsPlanChartProps";
import { CareUnitTypeEnum } from "../../../../../utils/constants/careUnitTypes";

const ProductionVsPlanChart = ({
  selectedTypeOfPlan,
  chartTitle,
  isPlacedOnDashboard,
  areaChartData,
  columnChartData,
  bookedData,
  recoveryPlan,
  timePeriodType,
  dateRange,
}: ProductionVsPlanProps) => {
  const { t: translate } = useTranslation();

  const isLargeScreen = useMediaQuery(SCREEN_SIZES.lg);
  const chartFontSize = getChartFontSize(isPlacedOnDashboard ? true : false, isLargeScreen);

  const generateLegendIcon = (name: string, isSeriesVisible: boolean) => {
    let imagePath = "";

    if (name === translate("plan")) {
      imagePath = "/images/circle.svg";
    } else if (name === translate("outcome")) {
      imagePath = "/images/circle-svg-colored.svg";
    } else if (name === translate("recovery_plan")) {
      imagePath = "/images/circle-svg-yellow.svg";
    } else if (name === translate("booked")) {
      imagePath = "/images/circle-svg-blue.svg";
    }

    const imageDimension =
      name === translate("outcome") ? "width = '48px' height = '40px'>" : "width = '16px' height = '16px'>";

    const img: string =
      `<div class="highcharts-custom-legend ${isPlacedOnDashboard && "dashboard"} ${
        !isSeriesVisible && "highcharts-custom-selected"
      }"> <img src =` +
      imagePath +
      " " +
      imageDimension +
      "  " +
      name +
      "</div>";

    return img;
  };

  const daysOfWeek = [
    translate("mon"),
    translate("tue"),
    translate("wed"),
    translate("thu"),
    translate("fri"),
    translate("sat"),
    translate("sun"),
  ];

  const getISOWeekNumber = (date: Date): number => {
    const targetDate = new Date(date.getTime());
    targetDate.setHours(0, 0, 0, 0);
    targetDate.setDate(targetDate.getDate() + 3 - ((targetDate.getDay() + 6) % 7));
    const firstThursday = new Date(targetDate.getFullYear(), 0, 4);
    const weekNumber = Math.ceil(
      ((targetDate.getTime() - firstThursday.getTime()) / 86400000 + firstThursday.getDay() + 1) / 7
    );

    return weekNumber;
  };

  const extractExactDate = (input: string): string | null => {
    const pattern = /([^\s,]+), (\d{4}-\d{2}-\d{2})--(\d{4}-\d{2}-\d{2})/;
    const match = input.match(pattern);

    if (match) {
      const day = match[1];
      const startDateString = match[2];
      const startDate = new Date(startDateString);

      const targetDayIndex = daysOfWeek.indexOf(day);

      let startDayIndex = startDate.getDay();

      if (startDayIndex === 0) {
        startDayIndex = 6;
      } else {
        startDayIndex -= 1;
      }

      const dayDifference = targetDayIndex - startDayIndex;

      const exactDate = new Date(startDate);
      exactDate.setDate(startDate.getDate() + dayDifference);

      return exactDate.toISOString().split("T")[0];
    }

    return null;
  };

  const createXAxisCategories = () => {
    const dateRanges: string[] = [];
    let i = 0;
    while (i < dateRange.length) {
      const startDate = dateRange[i];
      const endDate = dateRange[i + 6];
      const dateRangeString = `${startDate}--${endDate} (W/${getISOWeekNumber(new Date(startDate))})`;
      dateRanges.push(dateRangeString);
      i = i + 7;
    }

    const perDayBarChartCategories = dateRanges.map((dateRange) => ({
      name: dateRange,
      categories: daysOfWeek,
    }));

    return perDayBarChartCategories;
  };

  const areaChartOptions = {
    chart: {
      backgroundColor: "white",
      className: "product_vs_plan_chart",
      style: {
        fontFamily: "Open Sans",
        textOutline: 0,
        fontSize: chartFontSize,
      },
    },

    title: {
      text: chartTitle,
      style: {
        fontSize: "20px",
      },
    },

    xAxis:
      timePeriodType === 2
        ? {
            categories: createXAxisCategories(),
            min: 0,
            max: dateRange.length - 1,
            startOnTick: true,
            tickInterval: 2,
            tickWidth: 1,
            tickmarkPlacement: "on",
            labels: {
              style: {
                fontSize: isLargeScreen ? 10 : 9,
              },
            },
          }
        : {
            categories: false,
            allowDecimals: false,
            max: 52,
            min: 1,
            startOnTick: false,
            tickInterval: 2,
            labels: {
              style: {
                fontSize: chartFontSize,
              },
            },
          },
    yAxis: {
      title: {
        text:
          Number(selectedTypeOfPlan?.value) === CareUnitTypeEnum.OUTPATIENT
            ? translate("no_of_visits")
            : translate("no_of_care_episodes"),
        style: {
          fontSize: chartFontSize,
        },
      },

      labels: {
        style: {
          fontSize: chartFontSize,
        },
      },
    },

    legend: {
      useHTML: true,
      verticalAlign: "top",
      symbolWidth: 0,
      margin: 30,
      itemStyle: {
        fontSize: chartFontSize,
      },

      style: {
        fontSize: chartFontSize,
      },

      labelFormatter: function (this: AxisLabelObject): string | undefined {
        console.log("labelFormatter", this);
        const name = this.name;

        const chart = this.chart;

        let isPlanSeriesVisible = false;
        let isOutcomeSeriesVisible = false;
        let isRecoveryPlanSeriesVisible = false;
        let isBookedSeriesVisible = false;

        chart.series.forEach((s: Highcharts.Series) => {
          if (s.name === translate("plan") && s.visible) {
            isPlanSeriesVisible = true;
          } else if (s.name === translate("outcome") && s.visible) {
            isOutcomeSeriesVisible = true;
          } else if (s.name === translate("recovery_plan") && s.visible) {
            isRecoveryPlanSeriesVisible = true;
          } else if (
            s.name === translate("booked") &&
            s.visible &&
            Number(selectedTypeOfPlan?.value) === CareUnitTypeEnum.OUTPATIENT
          ) {
            isBookedSeriesVisible = true;
          }
        });

        if (name === translate("plan")) {
          return generateLegendIcon(name, isPlanSeriesVisible);
        } else if (name === translate("outcome")) {
          return generateLegendIcon(name, isOutcomeSeriesVisible);
        } else if (name === translate("recovery_plan")) {
          return generateLegendIcon(name, isRecoveryPlanSeriesVisible);
        } else if (name === translate("booked")) {
          return generateLegendIcon(name, isBookedSeriesVisible);
        }
      },
    },

    tooltip: {
      shared: false,
      formatter: function (this: Highcharts.TooltipFormatterContextObject): string {
        let tooltipInfo = "";
        const point = this.point as Highcharts.Point;
        const series = this.series as Highcharts.Series;
        const otherSeriesData = series.chart.series
          .filter((s) => s.name !== series.name)
          .map((s) => s.data.filter((d) => d.x === point.x));

        if (this.series.name === translate("outcome") && otherSeriesData[0][0] !== undefined) {
          tooltipInfo =
            timePeriodType === 2
              ? `${translate("date")} : ${extractExactDate(point.category.toString())} <br />` +
                `${translate("plan")} ${otherSeriesData[0][0].y} <br /> ` +
                `${translate("outcome")} ${point.y}`
              : `${translate("week")} : ${point.x} <br />` +
                `${translate("plan")} ${otherSeriesData[0][0].y} <br /> ` +
                `${translate("outcome")} ${point.y} `;
        } else if (this.series.name === translate("recovery_plan") && otherSeriesData[0][0] !== undefined) {
          tooltipInfo =
            timePeriodType === 2
              ? `${translate("date")} : ${extractExactDate(point.category.toString())} <br />` +
                `${translate("plan")} : ${otherSeriesData[0][0].y} <br />` +
                `${translate("booked")} : ${otherSeriesData[2][0].y} <br />` +
                `${translate("recovery_plan")} : ${point.y}`
              : `${translate("week")} : ${point.x} <br />` +
                `${translate("plan")} : ${otherSeriesData[0][0].y} <br />` +
                `${translate("booked")} : ${otherSeriesData[2][0].y} <br />` +
                `${translate("recovery_plan")} : ${point.y}`;
        }

        return tooltipInfo;
      },
    },

    plotOptions: {
      area: {
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
      column: {
        borderWidth: 0,
      },
    },

    series: [
      {
        type: "area",
        name: translate("plan"),
        data: areaChartData?.map((ad) => {
          return timePeriodType === 2 ? { y: ad.productionPlan } : { x: Number(ad.id), y: ad.productionPlan };
        }),
        color: "#BFBFBF",
        enableMouseTracking: false,
      },
      {
        type: "column",
        name: translate("outcome"),
        data: columnChartData?.outcomeData.map((data, index) => {
          return timePeriodType === 2
            ? { y: data.outcome, color: columnChartData.isWithinLimit[index] ? "#4A6541" : "#BE2A23" }
            : {
                x: Number(data.id),
                y: data.outcome,
                color: columnChartData.isWithinLimit[index] ? "#4A6541" : "#BE2A23",
              };
        }),
        color: "#4A6541",
        pointWidth: 10,
      },
      {
        type: "column",
        name: translate("booked"),
        data: bookedData?.map((data) => {
          return timePeriodType === 2
            ? { y: data.bookedValue }
            : {
                x: Number(data.id),
                y: data.bookedValue,
              };
        }),
        color: "#2F4F74",
        pointWidth: 10,
        enableMouseTracking: false,
      },
      {
        type: "line",
        name: translate("recovery_plan"),
        data: recoveryPlan?.map((rp) => {
          return timePeriodType === 2 ? { y: rp.recoveryPlan } : { x: Number(rp.id), y: rp.recoveryPlan };
        }),
        color: "#E5A32B",
        marker: {
          enabled: false,
        },
      },
    ],
  };

  return (
    <div className="!h-full max-h-full">
      <HighchartsReact
        containerProps={{ style: { height: "100%" } }}
        highcharts={Highcharts}
        options={areaChartOptions}
      />
    </div>
  );
};

export default ProductionVsPlanChart;
