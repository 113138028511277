import { Select } from "../../../components";
import { GridLayout } from "../../../layouts";
import React, { useEffect, useState } from "react";
import { RootState } from "../../../redux/store";
import { useSelector } from "react-redux";
import ResultService from "../../../services/resultService";
import "webdatarocks/webdatarocks.min.css";
import WebDataRocks, { ColumnSize } from "webdatarocks";
import Highcharts from "highcharts";
import HighchartsSankey from "highcharts/modules/sankey";
import { AxiosResponse } from "axios";
import BudgetResponse from "../../../interfaces/budget/budget-response";

//css
import "./sankey.css";
import { useTranslation } from "react-i18next";
import { ISelectOption } from "../../../interfaces";
import { AppInsightService } from "../../../services";

HighchartsSankey(Highcharts);

const Budget = () => {
  useEffect(() => {
    AppInsightService.trackPageView({ name: "Budget" });
  }, []);
  const { t: translate } = useTranslation();
  const careUnitId = useSelector((state: RootState) => state.globalFilter.filterCareUnit);
  const [selectedTabIndex] = useState<number>(0);
  const scenario = useSelector((state: RootState) => state.globalFilter.filterScenario);

  const [sankeyChartData, setSankeyChartData] = useState([]);
  const type_of_inputs: ISelectOption[] = [
    {
      label: translate("fte"),
      value: "fte",
    },

    {
      label: translate("cost") + " (kSEK)",
      value: "cost",
    },

    {
      label: translate("working_hours"),
      value: "working_hours",
    },
  ];

  const type_of_competence: ISelectOption[] = [
    {
      label: translate("CompetenceName"),
      value: "competenceName",
    },

    {
      label: translate("competenceGroupLvl1"),
      value: "competenceGroupLvl1",
    },

    {
      label: translate("competenceGroupLvl2"),
      value: "competenceGroupLvl2",
    },

    {
      label: translate("Profession"),
      value: "profession",
    },
  ];

  const [typesOfInputs, setTypesOfInputs] = useState<ISelectOption[]>([]);
  const [selectedInput, setSelectedInput] = useState<ISelectOption>(type_of_inputs[0]);

  const [typesOfCompetence, setTypesOfCompetence] = useState<ISelectOption[]>([]);
  const [selectedCompetenceType, setSelectedCompetenceType] = useState<ISelectOption>(type_of_competence[0]);

  useEffect(() => {
    setTypesOfInputs(type_of_inputs);
    setTypesOfCompetence(type_of_competence);
  }, []);

  useEffect(() => {
    if (selectedTabIndex === 1) {
      ResultService.getSankeyChartData(careUnitId, selectedInput.value, selectedCompetenceType.value, scenario).then(
        (res) => {
          setSankeyChartData(res.data);
          setdataLoaded(true);
        }
      );
    }
  }, [careUnitId, selectedInput, selectedCompetenceType, selectedTabIndex, scenario]);

  const [tableData, setTableData] = useState<BudgetResponse[]>([]);
  const webDataRocksref = React.useRef<WebDataRocks.Pivot>();
  const [dataLoaded, setdataLoaded] = useState(false);

  //budget table column widths
  const budgetTableColWidths: ColumnSize[] = [];
  const commonWidth = 140;

  for (let i = 0; i < 14; i++) {
    budgetTableColWidths.push({
      idx: i,
      width: i < 2 ? 200 : i === 6 || i === 7 || i === 13 ? 200 : commonWidth,
    });
  }

  useEffect(() => {
    ResultService.getTotalCost(careUnitId, scenario).then((res: AxiosResponse<BudgetResponse[]>) => {
      const budgetData: BudgetResponse[] = res.data;
      budgetData.map((r) => {
        r.measureName = r.measureName && translate(r.measureName);
        r.type = r.type && translate(r.type);
      });

      setTableData(budgetData);
      setdataLoaded(true);
    });
  }, [careUnitId, scenario]);

  const getGridHeight = () => {
    const height = screen.height;
    if (height >= 1080) {
      return height * 0.75;
    } else return height * 0.55;
  };

  useEffect(() => {
    if (selectedTabIndex == 0) {
      setdataLoaded(false);
      const pivot = new WebDataRocks({
        container: document.getElementById("pivot"),
        height: getGridHeight(),
        report: {
          dataSource: {
            dataSourceType: "json",
            data: tableData,
          },
          slice: {
            rows: [
              {
                uniqueName: "unit",
                caption: "Unit",
              },

              {
                uniqueName: "shiftName",
                caption: "Shift Name",
              },
            ],
            columns: [
              {
                uniqueName: "type",
                sort: "unsorted",
              },
              {
                uniqueName: "measureName",
                sort: "unsorted",
              },
            ],
            measures: [
              {
                uniqueName: "value",
                aggregation: "sum",
                format: "total_fte_format",
              },
            ],
          },
          formats: [
            {
              name: "total_fte_format",
              decimalSeparator: ",",
              decimalPlaces: 1,
            },
          ],
          tableSizes: {
            columns: budgetTableColWidths,
          },
          options: {
            showAggregationLabels: false,
            grid: {
              type: "classic",
              showGrandTotals: "columns",
              showTotals: true,
              showHeaders: false,
              showHierarchyCaptions: false,
            },
          },
        },
        customizeCell(cell, data) {
          const cellValue = pivot.getCell(1, 2);
          const isExpanded = cellValue.label === "" ? true : false;
          const cellValue2 = pivot.getCell(1, 3);

          if (
            ((data.columnIndex === 2 && cell.text === "") ||
              data.columnIndex === 8 ||
              (data.columnIndex === 3 && cell.text === "")) &&
            data.rowIndex === 1
          ) {
            cell.text = translate("Total");
          }

          if (
            data.columnIndex &&
            data.rowIndex &&
            cell.text &&
            ((isExpanded &&
              cellValue2.label !== "" &&
              data.columnIndex >= 2 &&
              data.columnIndex <= 7 &&
              data.rowIndex > 1) ||
              (!isExpanded && data.columnIndex === 2 && data.rowIndex > 0) ||
              (isExpanded && cellValue2.label === "" && data.columnIndex === 2 && data.rowIndex > 1))
          ) {
            cell.text = parseFloat(cell.text.replace(" ", "")).toFixed(0);
          }
        },
      });

      pivot.on("reportcomplete", function () {
        pivot.expandData("unit");
        pivot.expandData("shiftName");
      });

      webDataRocksref.current = pivot;
    }
  }, [careUnitId, dataLoaded, selectedTabIndex]);

  //sankey chart
  useEffect(() => {
    if (selectedTabIndex == 1) {
      setdataLoaded(false);
    }
  }, [selectedTabIndex, sankeyChartData]);

  const handleTypesOfInputSelect = (selectedOption: ISelectOption) => {
    setSelectedInput(selectedOption);
  };

  const handleCompetenceInputSelect = (selectedOption: ISelectOption) => {
    setSelectedCompetenceType(selectedOption);
  };

  return (
    <GridLayout additionalStyles="mt-0 px-0">
      <div className="col-start-1 col-end-13">
        <div className="mt-0 overflow-auto bg-[#F8F8F8] px-2 pt-4 lg:h-[79vh] xl:h-[80vh]">
          {selectedTabIndex == 0 && (
            <div className="flex">
              <div id="pivot" className="min-w-screen flex-grow"></div>
              {/* <div className="overflow-auto pl-7">
                <BudgetChart />
              </div> */}
            </div>
          )}
          {selectedTabIndex == 1 && (
            <div className="flex h-full flex-row" id="wraper">
              <div className="h-full w-5/6" id="sankey"></div>
              <div className="ml-5 h-full w-1/6">
                <div className="mb-4">
                  <Select
                    placeholder={translate("type_of_input")}
                    options={typesOfInputs}
                    onSelectOption={handleTypesOfInputSelect}
                    selectedOption={selectedInput}
                  />
                </div>

                <div className="mb-4">
                  <Select
                    placeholder={translate("competence_categories")}
                    options={typesOfCompetence}
                    onSelectOption={handleCompetenceInputSelect}
                    selectedOption={selectedCompetenceType}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </GridLayout>
  );
};

export default Budget;
