import React, { useState } from "react";
import Draggable from "react-draggable";
import { ResizableBox, ResizeCallbackData } from "react-resizable";

import { DraggableResizableProps } from "../../interfaces/props/draggableResizableProps";
import Icon from "../icons/Icons";

import "react-resizable/css/styles.css";

const Header = ({ title, onClose }: { title: string; onClose: () => void }): JSX.Element => (
  <div className="handle flex cursor-move items-center justify-between border-b px-4 py-2">
    <h2 className="text-lg font-semibold">{title}</h2>
    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
      <Icon variant="close" width={16} height={16} />
    </button>
  </div>
);

const Body = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <div className="flex-grow overflow-y-auto p-4">{children}</div>
);

const DraggableResizableComponent: React.FC<DraggableResizableProps> = ({
  isOpen,
  onClose,
  children,
  title,
  initialSize = { width: 700, height: 700 },
  minConstraints = [200, 200],
  maxConstraints = [800, 600],
}) => {
  const [size, setSize] = useState(initialSize);

  const onResize = (event: React.SyntheticEvent, { size }: ResizeCallbackData) => {
    setSize({ width: size.width, height: size.height });
  };

  if (!isOpen) return null;

  return (
    <div className="draggable-container fixed inset-0 z-50">
      <Draggable
        handle=".handle"
        bounds="parent"
        defaultPosition={{ x: window.innerWidth / 2 - size.width / 2, y: window.innerHeight / 2 - size.height / 2 }}
      >
        <div className="absolute">
          <ResizableBox
            width={size.width}
            height={size.height}
            minConstraints={minConstraints}
            maxConstraints={maxConstraints}
            onResize={onResize}
            resizeHandles={["se"]}
          >
            <div className="flex h-full w-full flex-col rounded-lg bg-white shadow-lg">
              <Header onClose={onClose} title={title}></Header>
              <Body>{children}</Body>
            </div>
          </ResizableBox>
        </div>
      </Draggable>
    </div>
  );
};

export default DraggableResizableComponent;
